<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="6">
        <div class="d-flex justify-content-start">
          <b-avatar
            rounded
            v-if="profileData"
            :text="avatarText"
            size="60px"
            variant="light-primary"
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1 mt-1">
              <h4 class="mb-0">
                {{ adSoyad }}
              </h4>
              <span class="card-text">@{{ profileData.sKullaniciKodu }}</span>
            </div>
          </div>
        </div>

      </b-col>
      <b-col cols="12" md="6">
        <table class="mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Tür') }}</span>
            </th>
            <td class="pb-50">
              <b-badge :variant="resolveUserRoleVariant(profileData.kullaniciTipi)" class="mt-1 float-right badge-glow">
                <span>{{ resolveKullaniciTipi(profileData.kullaniciTipi) }}</span>
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Oluşturma Tarihi') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <span class="float-right">{{ formatDateToDMY(profileData.dtOlusTar) }}</span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <!-- form -->
    <validation-observer ref="userFields">
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('Ad')"
          >
           <validation-provider #default="{ errors }" rules="required" name="Ad">
            <b-form-input
              id="sAd"
              :state="errors.length > 0 ? false:null"
              v-model="profileData.sAd"
              :placeholder="$t('Ad')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Soyad')"
          >
          <validation-provider #default="{ errors }" rules="required" name="Soyad">
            <b-form-input
              id="sSoyad"
              :state="errors.length > 0 ? false:null"
              v-model="profileData.sSoyad"
              :placeholder="$t('Soyad')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
            <b-form-group :label="$t('Telefon')">
              <validation-provider #default="{ errors }" rules="required" name="Telefon">
                <cleave id="sTelefon" v-model="profileData.sTelefon" :state="errors.length > 0 ? false:null" placeholder="(999) 999-9999" class="form-control" :raw="false" :options="options.phone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
        </b-col>
        <b-col sm="6">
            <b-form-group :label="$t('Cep Telefonu')">
              <validation-provider #default="{ errors }" rules="required" name="Cep Telefonu">
                <cleave id="sCepTelefon" v-model="profileData.sCepTelefon" :state="errors.length > 0 ? false:null" placeholder="(999) 999-9999" class="form-control" :raw="false" :options="options.phone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group
            :label="$t('E-Posta')"
          >
            <b-form-input
              disabled
              v-model="profileData.sEMail"
              name="email"
              :placeholder="$t('E-Posta')"
            />

          </b-form-group>
        </b-col>
        <b-col cols="12">
          <save-button
          :showSaveAndClose="false"
          :showClose="false"
          :onClickSave="submit"
          />
        </b-col>
      </b-row>
      
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// Alert
import AlertService from '@/common/alert.service'

import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations';

import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import SaveButton from '@/components/SaveButton.vue';
import { formatDateToDMY } from '@core/utils/filter'

export default {
  components: {
    Cleave,
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    profileData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      required,
      avatarText: '',
      adSoyad: '',
      options: {    
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
    }
  },
  mounted() {
    this.avatarText = this.profileData.sAd[0] + this.profileData.sSoyad[0];
    this.adSoyad = `${this.profileData.sAd} ${this.profileData.sSoyad}`.toUpperCase();
  },

  methods: {
    resolveKullaniciTipi(role) {
      return KullaniciYonetimService.findKullaniciTipiNam(role);
    },
    resolveUserRoleVariant(role) {
      if (role === 1) return 'primary'
      if (role === 2) return 'warning'
      if (role === 3) return 'success'
      if (role === 4) return 'info'
      return 'primary'
    },
    formatDateToDMY,
    submit() {
       this.$refs.userFields.validate().then(success => {
        if (success) {
          const { sAd, sSoyad, sCepTelefon, sTelefon } = this.profileData;
          this.$store.dispatch('profileSettings/saveProfile', { sAd, sSoyad, sCepTelefon, sTelefon })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
            } else {
              AlertService.error(this, response.message)
            }
          });
        }
       })
    },
  },
}
</script>
